import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faDiscord, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import './contact.css';

const Contact = () => {
  const [buttonTransform, setButtonTransform] = useState('');
  const [bgTransform, setBgTransform] = useState('');

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const dx = x - centerX;
    const dy = y - centerY;

    setButtonTransform(`translate3d(${dx * 0.3}px, ${dy * 0.3}px, 0)`);
    setBgTransform(`translate3d(${dx * 0.6}px, ${dy * 0.6}px, 0)`);
  };

  const handleMouseLeave = () => {
    setButtonTransform('');
    setBgTransform('');
  };

  return (
    <section className='contact' id='contact'>
      <div >
        <span className='pageTitle'>&#10023;Contact&#10023;</span> <br />
        <div>
          <div className='contactContainer'>
            <span> Let's build the future </span>
            <span> together </span>
            <div className='buttonWrapper' onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} style={{ transform: buttonTransform }}>
              <a role='button' href='/contact' className='button'>
                Contact Me
              </a>
              <div className='buttonBg' style={{ transform: bgTransform }}></div>
            </div>
          </div>

          <div className='mediaContainer'>
            <span> You can find me on </span>
            <span> social media </span>

            <div className="iconContainer">
              <a href="https://www.linkedin.com/in/davis-jesse97" target="_blank" rel="noopener noreferrer" className="mediaIcon" style={{ '--hover-color': '#1DA1F2' }}>
                <FontAwesomeIcon icon={faLinkedin} />
                <span>Linked in</span>
              </a>
              <a href="https://github.com/Lumenita" target="_blank" rel="noopener noreferrer" className="mediaIcon" style={{ '--hover-color': '#90a0a5' }}>
                <FontAwesomeIcon icon={faGithub} />
                <span>Github</span>
              </a>
              <a href="https://www.discordapp.com/users/289981330735759363" target="_blank" rel="noopener noreferrer" className="mediaIcon" style={{ '--hover-color': '#8e4dbd' }}>
                <FontAwesomeIcon icon={faDiscord} />
                <span>Discord</span>
              </a>
              <a href="https://www.instagram.com/luminitatv" target="_blank" rel="noopener noreferrer" className="mediaIcon" style={{ '--hover-color': '#d98c13' }}>
                <FontAwesomeIcon icon={faInstagram} />
                <span>Instagram</span>
              </a>
              <a href="https://ko-fi.com/luminitatv" target="_blank" rel="noopener noreferrer" className="mediaIcon" style={{ '--hover-color': '#db3737' }}>
                <FontAwesomeIcon icon={faMugHot} />
                <span>Ko-fi</span>
              </a>
            </div>
          </div>
        </div>

        <span className='pageTitle'>Made with React by Luminita. ~2023</span>
      </div>
    </section>
  )
}

export default Contact