import React, { useState, useEffect, useRef } from "react";
import './typingText.css';

const TypingText = ({ text }) => {
  const [typedText, setTypedText] = useState('');
  const typedRef = useRef('');

  useEffect(() => {
    typedRef.current = '';
    setTypedText(typedRef.current);
    let index = 0;
    const timer = setInterval(() => {
      typedRef.current += text.charAt(index);
      setTypedText(typedRef.current);
      index++;
      if (index > text.length - 1) {
        clearInterval(timer);
      }
    }, 35);
    return () => clearInterval(timer);
  }, [text]);

  return <div className="typing-container">{typedText}</div>;
};

export default TypingText;
