import React from 'react';
import { SliderComp } from '../../../components';
import { ProjectList } from '../../../helpers/ProjectList';
import './work.css';

const Work = () => { 

  return (
    <section className='work' id='work'>
      <div className='workContainer'>
        <span className='pageTitle'>&#10023;Work&#10023;</span> <br />
        <span className='ProjectTitle'> Responsive Web Development </span> <br />
        <span className='ProjectTitle'> All example projects are still wip 7/29/23</span>
      </div>
      <SliderComp data={ProjectList}/>

    </section>
  )
}

export default Work