import React, {useState, useEffect, useRef} from 'react';
import { useSpring, animated, config } from 'react-spring';
import { gsap } from 'gsap';
import { AnimatedWords } from '../../../components';

import './start.css';

const texts = [
  ' The future',
  ' Websites',
  ' UX/UI',
  ' Games'
];

const Start = ({ refs }) => {
  const pageTitleRef = useRef(null);
  const h1Ref = useRef(null);
  const h2Ref = useRef(null);
  const animatedWordsRef = useRef(null);
  const letsGoRef = useRef(null);

  const [textIndex, setTextIndex] = useState(texts.length - 1);
  const { morph } = useSpring({
    from: { morph: 0 },
    to: { morph: 1 },
    reset: true,
    config: config.molasses,
    onRest: () => setTextIndex(prevIndex => (prevIndex + 1) % texts.length),
  });

  useEffect(() => {
    morph.start({ from: 0, to: 1 });
  }, [textIndex, morph]);

  useEffect(() => {
    const elements = [pageTitleRef.current, h1Ref.current, h2Ref.current, animatedWordsRef.current, letsGoRef.current];
  
    elements.forEach(el => {
      gsap.set(el, { autoAlpha: 0, x: -50 });
    });  
    gsap.to(elements, { autoAlpha: 1, x: 0, stagger: 0.5, duration: 1, ease: "power1.out" });
  }, []);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: 'smooth',
    });
};

  return (
    <section className='start' id='start'>
      <div>
        <span className='pageTitle' ref={pageTitleRef}>&#10023;Start&#10023;</span> <br />
        <h1 ref={h1Ref}> Hi, my name is <strong className='nameColor'> Jesse Davis </strong></h1>
        <h2 ref={h2Ref}>
          &exist;x P&#x2768;Jesse&#x2769; &#8594;
          <span className='designDevelop'> design </span>
          &and;
          <span className='designDevelop'> develop </span>
          :
          <animated.span className='animate'
            style={{
              filter: morph.to(m => `blur(${8 / m - 8}px)`),
              opacity: morph.to(m => `${Math.pow(m, 0.4) * 100}%`)
            }}
          >
            {texts[textIndex]}
          </animated.span> 
        </h2>
        <div ref={animatedWordsRef}>
          <AnimatedWords />
        </div>
        <span className='letsGo' ref={letsGoRef} onClick={() => scrollToSection(refs.work)}> Lets go! </span>
      </div>
    </section>
  )
}

export default Start