import React, {useRef} from 'react';
import '../styles/ContactMe.css'
import emailjs from '@emailjs/browser'

function ContactMe() {
  const refForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t7t6le6', 'template_xdddepo', refForm.current, 'i24n2l1Fn_WFyNPq-')
    .then(function() {
      alert('Message successfully sent!')
      window.location.reload(false)
    }, function() {
      alert('Failed to send the message, please try again')
    });
  }
    return (
      <div className='contactMeContainer'>
        <div className='contactName'>
          <p><span>&#10023;</span>Luminita<span>&#10023;</span></p>
        </div>
        <div className='contactMeInfo'>
          <span> Contact Me </span><br/>
          <span> hello, thank you for your interest in my work and your time in reaching out to me </span>
        </div>
        <div className='contactForm'>
          <form ref={refForm} onSubmit={sendEmail}>
            <ul>
              <li className='contactHalf'>
                <input type='text' name='name' placeholder='Name' required />
              </li>
              <li className='contactHalf2'>
                <input type='email' name='email' placeholder='Email' required />
              </li>
              <li className='contactSubject'>
                <input type='text' name='subject' placeholder='Subject' required />
              </li>
              <li>
                <textarea name='message' placeholder='Message' rows={4} required></textarea>
              </li>
              <input className='flatButton' type='submit' value='SEND' />
            </ul>
          </form>
        </div>
      </div>
      

    )
  }
  
  export default ContactMe