import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ refs }) => {
    const [expandNavbar, setExpandNavbar] = useState(false);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('');
    const location = useLocation();

    useEffect(() => {
        setExpandNavbar(false);

        const handleScroll = () => {
            const currentScrollPosition = window.pageYOffset;
            if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 50) {
                setScrollDirection('hide');
            } else {
                setScrollDirection('');
            }
            setLastScrollPosition(currentScrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollPosition, location]);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (

        <div className={`navbar ${expandNavbar ? 'open' : 'close'} ${scrollDirection}`} id={expandNavbar ? 'open' : 'close'}>
            <div className='name'>
                <p><span>&#10023;</span>Luminita<span>&#10023;</span></p>
            </div>

            <div className='toggleButton'>
                <button onClick={() => {
                    setExpandNavbar((prev) => !prev);
                }} >
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>

            <nav className='sections'>
                <ul>
                    <li onClick={() => scrollToSection(refs.start)}>
                        Start
                    </li>
                    <li onClick={() => scrollToSection(refs.work)}>
                        Work
                    </li>
                    <li onClick={() => scrollToSection(refs.project)}>
                        Project
                    </li>
                    <li onClick={() => scrollToSection(refs.about)}>
                        About
                    </li>
                    <li onClick={() => scrollToSection(refs.contact)}>
                        Contact
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar