import React, { useState } from 'react';
import { TypingText } from '../../components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './sliderComp.css';

const SliderComp = ({ data }) => {
   const [currentSlide, setCurrentSlide] = useState(0);

   function SampleNextArrow(props) {
      const { className, onClick } = props;
      return (
         <div
            className={className}
            onClick={onClick}
         >
            &gt;
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { className, onClick } = props;
      return (
         <div
            className={className}
            onClick={onClick}
         >
            &lt;
         </div>
      );
   }

   const settings = {
      afterChange: current => setCurrentSlide(current),
      dots: false,
      infinite: true,
      centerMode: true,
      swipeToSlide: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1280,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <div>
         <Slider {...settings}>
            {data.map((item, index) => (
               <div key={index}>
                  <img src={item.src} alt='carousel' className='image' />
               </div>
            ))}
         </Slider>
         <div className='slideContainer'>
            <div>
               <div className='slideTitle'>
                  <TypingText text={data[currentSlide].title} />
               </div>
               <div className='slideskills' >
                  <TypingText text={data[currentSlide].skills} />
               </div>
            </div>
         </div>

         <div className='slideContainer'>
            <div className='slideInfo' >
               <TypingText text={data[currentSlide].info} /> 
            </div>
            <div className='buttonContainer'>
               <div className='slideUrl wip'>
                  <a role='button' href={data[currentSlide].linkurl} target="_blank" rel="noopener noreferrer" className='button2'>
                     Visit
                  </a>
               </div>
               <div className='slidePreview'>
                  <a role='button' href={data[currentSlide].number} className='button2'>
                     Preview Page
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SliderComp