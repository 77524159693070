import React, { useState, useEffect } from 'react';
import './animatedWords.css';

const words = [
    'Let\'s build the future together', 
    'What are you waiting for', 
    'Scroll down and check my work', 
    'I\'m looking forward to hearing from you'];
let len = words.length;
let skip_delay = 30;
let speed = 40;

const AnimatedWords = () => {
    const [part, setPart] = useState('');
    const [i, setI] = useState(0);
    const [offset, setOffset] = useState(0);
    const [forwards, setForwards] = useState(true);
    const [skip_count, setSkipCount] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (forwards) {
          if (offset >= words[i].length) {
            setSkipCount(skip_count + 1);
            if (skip_count === skip_delay) {
              setForwards(false);
              setSkipCount(0);
            }
          }
        }
        else {
          if (offset === 0) {
            setForwards(true);
            setI((i + 1) % len);
            setOffset(0);
          }
        }
        setPart(words[i].substr(0, offset));
        if (skip_count === 0) {
          if (forwards) {
            setOffset(offset + 1);
          }
          else {
            setOffset(offset - 1);
          }
        }
      }, speed);
      return () => clearInterval(interval);
    }, [i, offset, forwards, skip_count]);
  
    return <h3>{part}<span className="cursor">|</span></h3>;    
  }

export default AnimatedWords