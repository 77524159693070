import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { ProjectList } from '../helpers/ProjectList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faArrowRightLong, faArrowLeftLong, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import '../styles/ProjectDisplay.css';

function ProjectDisplay() {
   const { id } = useParams();
   const project = ProjectList[id];
   const navigate = useNavigate();
   const location = useLocation();

   const goBack = () => {
      if (location.state && location.state.from) {
         navigate(location.state.from);
      } else {
         navigate(-1);
      }
   };

   return (
      <div className='projectList'>


         <div className='projectListContainer'>
            <div className='projectListHeader'>
               <a type="button" href='/' >
                  <FontAwesomeIcon icon={faArrowLeft} />
               </a>
               <div>
                  <a role='button' href={project.prev} className='buttonCycle'>
                     <FontAwesomeIcon icon={faArrowLeftLong} />
                  </a>
                  <span> / </span>
                  <a role='button' href={project.next}className='buttonCycle'>
                     <FontAwesomeIcon icon={faArrowRightLong} />
                  </a>
               </div>
            </div>
            <div className='projectListSkills'>
               <span> {project.skills} </span>
            </div>
            <div className='projectListTitle'>
               <span> <strong>{project.title}</strong></span>
            </div>
            <div className='projectListDesc'>
               <span> {project.info} </span>
            </div>
            <div className='projectListCTA wip'>
               <div className='slideUrl'>
                  <a role='button' href={project.linkurl} target="_blank" rel="noopener noreferrer" className='button2'>
                     Visit
                  </a>
               </div>
               <a href='/'>
                  <FontAwesomeIcon icon={faGithub} />
               </a>
            </div>
            <div className='projectListFooter wip'>
               <span> line at bottom with project number</span>
            </div>
         </div>

         <div className='imageContainer'>
            {project.images.map((imgSrc, index) => (
               <img key={index} src={imgSrc} alt="Placeholder" />
            ))}
         </div>
      </div>
   )
}

export default ProjectDisplay