import React, {useState, useEffect } from 'react';
import './project.css';
import github1 from '../../../images/github1.jpg';
import project6 from '../../../images/project6.jpg';
import chatbot from '../../../images/chatbot.png';

const Project = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [hoveredProject, setHoveredProject] = useState(null);
  const [visibleCursor, setVisibleCursor] = useState(false);

  const mouseMoveHandler = (event) => {
    setCursorPosition({ x: event.clientX + 10, y: event.clientY + 20 });
  };

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => document.removeEventListener('mousemove', mouseMoveHandler);
  }, []);

  useEffect(() => {
    if (hoveredProject) {
      setVisibleCursor(true);
    } else {
      const timer = setTimeout(() => {
        setVisibleCursor(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [hoveredProject]);

  return (
    <section className='project' id='project'>
      <div className='projectTitle'>
        <span className='pageTitle'>&#10023;Projects&#10023;</span> <br />
        <span>Check out my other projects</span>
      </div>
      <div className='projectContainer'>
        <div className='projectContainer1'>
          <div>
            <span>Github</span> <br />
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
            {visibleCursor && hoveredProject === 'github1' && (
                <div className={`cursorImage ${hoveredProject ? 'hovered' : ''}`}
                  style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px`, backgroundImage: `url(${github1})` }} >  
                </div>
              )}

              <span className='personalProject' onMouseEnter={() => setHoveredProject('github1')} onMouseLeave={() => setHoveredProject(false)} >
                abfalter - A FoundryVTT ttrpg System
              </span>
            </a><br/>
            <a href="https://github.com/Lumenita/Team4" target="_blank" rel="noopener noreferrer">
            {visibleCursor && hoveredProject === 'chatbot' && (
                <div className={`cursorImage ${hoveredProject ? 'hovered' : ''}`}
                  style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px`, backgroundImage: `url(${chatbot})` }} >  
                </div>
              )}
              <span className='personalProject' onMouseEnter={() => setHoveredProject('chatbot')} onMouseLeave={() => setHoveredProject(false)} >
                Uni Project - Chatbot
              </span>
            </a>
          </div>

          <div>
            <span>Other</span> <br />
            <a href="/" target="_blank" rel="noopener noreferrer">
            {visibleCursor && hoveredProject === 'project6' && (
                <div className={`cursorImage ${hoveredProject ? 'hovered' : ''}`}
                  style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px`, backgroundImage: `url(${project6})` }} >  
                </div>
              )}
              <span className='personalProject' onMouseEnter={() => setHoveredProject('project6')} onMouseLeave={() => setHoveredProject(false)} >
                PlaceHolder
              </span>
            </a>
          </div>
        </div>

        <div className='projectContainer1'>
          <div>
            <span>Archive</span> <br />
            <a href="/" target="_blank" rel="noopener noreferrer">
            {visibleCursor && hoveredProject === 'project6' && (
                <div className={`cursorImage ${hoveredProject ? 'hovered' : ''}`}
                  style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px`, backgroundImage: `url(${project6})` }} >  
                </div>
              )}
              <span className='personalProject' onMouseEnter={() => setHoveredProject('project6')} onMouseLeave={() => setHoveredProject(false)} >
                PlaceHolder
              </span>
            </a>
          </div>

        </div>
      </div>

    </section>
  )
}

export default Project