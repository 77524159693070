import React, { useRef } from 'react';
import { Start, Work, Projects, About, Contact } from '../containers';
import '../styles/Home.css';
import { Navbar } from '../components';

function Home() {
  const start = useRef(null);
  const work = useRef(null);
  const project = useRef(null);
  const about = useRef(null);
  const contact = useRef(null);

  const refs = { start, work, project, about, contact };
  
  return (
    <div>
      <Navbar refs={refs} />
      <div ref={start}><Start refs={refs}/></div>
      <div ref={work}><Work /></div>
      <div ref={project}><Projects /></div>
      <div className='transition'></div>
      <div ref={about}><About /></div>
      <div className='transition1'></div>
      <div ref={contact}><Contact /></div>
    </div>
  )
}

export default Home