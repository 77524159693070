import React from 'react';
import './about.css';

const About = () => {
  return (
    <section className='about' id='about'>
      <div>
        <span className='pageTitle'>&#10023;About&#10023;</span> <br />
        <span> Turning concepts into reality through design and development.</span>
        <div className='informationContainer'>
          <span className='aboutTitle'>Info</span>
          <p>
            Name
            <span className='whiteColor'> :</span>
            <span className='triColor'> Jesse Davis</span><br />
            Location
            <span className='whiteColor'> :</span>
            <span className='triColor'> Richardson, Tx</span><br />
          </p>
        </div>

        <div className='informationContainer'>
          <span className='aboutTitle'>Work Experience</span>
          <p>
            2023 - now
            <span className='whiteColor'> :</span>
            <span className='triColor'> Designer & Developer at Luminita.dev</span><br />
            2021 - now
            <span className='whiteColor'> :</span>
            <span className='triColor'> Personal FoundryVTT project</span><br />
            2020 - 2020
            <span className='whiteColor'> :</span>
            <span className='triColor'> Enumerator for the Census Bureau</span><br />
          </p>
        </div>

        <div className='informationContainer'>
          <span className='aboutTitle'>Education</span>
          <p>
            2019 - 2023
            <span className='whiteColor'> :</span>
            <span className='triColor'> Bachelors of Computer Science - the University of Texas at Dallas</span><br />
            2017 - 2018
            <span className='whiteColor'> :</span>
            <span className='triColor'> Associates of Applied Science - Tarrant County College</span><br />
            2015 - 2017
            <span className='whiteColor'> :</span>
            <span className='triColor'> Associates of Arts - Tarrant County College</span><br />
          </p>
        </div>

        <div className='informationContainer'>
          <span className='aboutTitle'>Skills</span>
          <p>
            <span className='triColor'>
              React, HTML/CSS/JS, Node.js, UX/UI, C#, UE5, Agile
            </span>
          </p>
        </div>
      </div>
    </section>
  )
}

export default About