import project1 from '../images/project1.jpg';
import project2 from '../images/project2.jpg';
import project3 from '../images/project3.jpg';
import project4 from '../images/project4.jpg';
import project5 from '../images/project5.jpg';
import project6 from '../images/project6.jpg';

export const ProjectList = [
   {
      src: project1,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) Recipe Finder (example)',
      skills: '#React #Tailwind #Mogno #Something',
      info: 'This website allows users to search for recipes based on different criteria, such as ingridients they have, dietary restrictions, cuisine, etc. Users may also favorite recipes for user accounts.',
      linkurl: 'https://www.google.com',
      number: '/project/0',
      next: '/project/1',
      prev: '/project/5',
   },
   {
      src: project2,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) Bank Showcase (example)',
      skills: '#JS #Html #Css #Other #Stuff',
      info: 'This website showcases a bank landing page, their trusted partners and more.',
      linkurl: 'xyzts',
      number: '/project/1',
      next: '/project/2',
      prev: '/project/0',
   },
   {
      src: project3,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) Restaurant Page (example)',
      skills: '#skills #stuff #things',
      info: 'This website showcases a restaurant, menu, and a way to make a reservation.',
      linkurl: 'xyzts',
      number: '/project/2',
      next: '/project/3',
      prev: '/project/1',
   },
   {
      src: project4,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) Artist Showcase (example)',
      skills: '#skill1 #skill2 #skill3',
      info: 'This website is an art gallary where users can post and scroll through various art, comment, like, and follow artists.',
      linkurl: 'xyzts',
      number: '/project/3',
      next: '/project/4',
      prev: '/project/2',
   },
   {
      src: project5,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) E-commerce (example)',
      skills: '#React #MongoDB #Node.js #Stuff',
      info: 'This website includes features like user authentication, a product catalog, a shopping cart, and  an order checkout.',
      linkurl: 'xyzts',
      number: '/project/4',
      next: '/project/5',
      prev: '/project/3',
   },
   {
      src: project6,
      images: [
         project1,
         project2,
         project3,
         project4,
         project5,
         project6
      ],
      title: '(WIP) Weather/News near me (example)',
      skills: '#Vue.js #Node.js #more&more',
      info: 'This websites uses user location to show nearby weather, weather alerts, news, traffic and more.',
      linkurl: 'xyzts',
      number: '/project/5',
      next: '/project/0',
      prev: '/project/4',
   },
]