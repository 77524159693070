import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './pages/Home';
import ProjectDisplay from './pages/ProjectDisplay';
import ContactMe from './pages/ContactMe';

function App() {
  return (
    <div className='App'>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/project/:id' element={<ProjectDisplay />} />
            <Route path='/contact' element={<ContactMe />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;